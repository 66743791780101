import { Utils } from '@web-nfb/frontend-static/src/js/custom/utils.js'

import 'jquery'
import 'bootstrap'
import 'bootstrap-select'
import 'formvalidation/dist/js/formValidation.js'
import 'formvalidation/dist/js/framework/bootstrap.js'
import './jquery-ui.js'
import 'dotdotdot'
import 'imagesloaded'
import '../layout/layoutStandalonePublic.js'
import '../custom/trackOutboundLink.js'
globalThis.Utils = Utils
